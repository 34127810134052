import PeopleIcon from "@mui/icons-material/People";
import { Paper } from "@mui/material";

const Card = (prop) => {
  // CSS Styling
  class cardStyles {
    static get statCardContainer() {
      return {
        width: "200px",
        height: "110px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        padding: "5px 5px",
        background: "#ffffff",
      };
    }

    static get statCardHdr() {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      };
    }

    static get cardTitle() {
      return {
        fontFamily: '"DM Sans", sans-serif',
        fontWeight: "500",
        fontSize: "16px",
        color: "var(--title-color)",
      };
    }

    static get statValueContainer() {
      return {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }

    static get statValue() {
      return {
        fontSize: "24px",
        color: "var(--dark-color)",
      };
    }
  }

  return prop.cardData.map((item) => {
    if (item.title !== null) {
      return (
        <Paper style={cardStyles.statCardContainer}>
          <div style={cardStyles.statCardHdr}>
            <h5 style={cardStyles.cardTitle}>
              {item.title.toString().toLowerCase()}
            </h5>
            <PeopleIcon fontSize="large" />
          </div>
          <div style={cardStyles.statValueContainer}>
            <h5 style={cardStyles.statValue}>{item.stat}</h5>
          </div>
        </Paper>
      );
    }
  });
};

export default Card;
