import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import IosShareIcon from "@mui/icons-material/IosShare";
import BarChartVisitorsMetrics from "./barChartVisitorsMetrics.js";
import { Container } from "@mui/material";
import axios from "axios";
import { VISITOR_METRICS_API } from "../../constants/constants.js";
import exportToExcel from "../ExportExcel/ExportExcel.js";

const VisitorMetrics = () => {
  const [visitorMetric, setVisitorMetric] = useState([]);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    const getVisitorMetricsData = async () => {
      try {
        const response = await axios.get(`${VISITOR_METRICS_API}`);

        if (response.data !== null) {
          setVisitorMetric(response.data);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getVisitorMetricsData();
  }, []);

  const months = visitorMetric.map((number) => {
    const monthName = monthNames[number._id - 1]; // Adjust for zero-based indexing
    const counts = number.count;
    return { monthName, counts };
  });
  // Static Data For Bar Chart
  const monthlyData = {
    labels: months.map((monthName) => monthName.monthName),
    datasets: [
      {
        label: "",
        barThickness: 35,
        backgroundColor: "#0ab39c",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: months.map((monthName) => monthName.counts),
        datalabels: {
          align: "start",
          anchor: "end",
        },
      },
    ],
  };

  // CSS Styling
  class visitorMetricsStyles {
    static get visitorMetricsContainer() {
      return {
        background: "#ffffff",
        height: "580px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }

    static get visitorMetricsHdr() {
      return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      };
    }

    static get hdrTitle() {
      return {
        fontFamily: '"DM Sans", sans-serif',
        fontSize: "16px",
        color: "var(--title-color)",
        paddingLeft: "10px",
        paddingTop: "10px",
      };
    }

    static get hdrRight() {
      return {
        display: "flex",
        gap: "20px",
        paddingTop: "10px",
        paddingRight: "10px",
      };
    }

    static get exportBtn() {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        fontSize: "12px",
        color: "var(--dark-color)",
        background: "var(--btn-bg-color)",
        fontFamily: '"DM Sans", sans-serif',
        border: "none",
        borderRadius: "8px",
      };
    }

    static get timeFrameFilterContainer() {
      return {
        display: "flex",
        gap: "5px",
        fontSize: "12px",
        color: "var(--dark-color)",
      };
    }

    static get timeFrameFilterBtn() {
      return {
        fontSize: "12px",
        color: "var(--dark-color)",
        background: "var(--btn-bg-color)",
        border: "none",
      };
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={visitorMetricsStyles.visitorMetricsContainer}
    >
      <div style={visitorMetricsStyles.visitorMetricsHdr}>
        <h5 style={visitorMetricsStyles.hdrTitle}>Visitor Metrics</h5>
        <div style={visitorMetricsStyles.hdrRight}>
          <Button
            style={visitorMetricsStyles.exportBtn}
            onClick={() => exportToExcel(months, "users.xlsx")}
          >
            <IosShareIcon fontSize="extra-small" />
            Export
          </Button>
          {/* <div style={visitorMetricsStyles.timeFrameFilterContainer}>
            <Button style={visitorMetricsStyles.timeFrameFilterBtn}>All</Button>
            <Button style={visitorMetricsStyles.timeFrameFilterBtn}>1M</Button>
            <Button style={visitorMetricsStyles.timeFrameFilterBtn}>6M</Button>
            <Button style={visitorMetricsStyles.timeFrameFilterBtn}>1Y</Button>
          </div> */}
        </div>
      </div>
      <div style={{ width: "auto", height: "auto" }}>
        <BarChartVisitorsMetrics data={monthlyData} />
      </div>
    </Container>
  );
};

export default VisitorMetrics;
