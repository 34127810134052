import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/material";
import axios from "axios";
import { TABLE_COUNT_API } from "../../constants/constants.js";

function createData(day, vip, media, students, total) {
  return { day, vip, media, students, total };
}

const rows = [
  createData("Day 1", 20, 20, 20, 60),
  createData("Day 2", 30, 30, 30, 90),
  createData("Day 3", 40, 40, 40, 120),
  createData("Day 4", 50, 50, 50, 150),
  createData("Day 5", 60, 60, 60, 180),
];

function formatDate(date) {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export default function TabularData() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getTableCountData = async () => {
      try {
        const response = await axios.get(`${TABLE_COUNT_API}`);
        if (response.data !== null) {
          function roleCount(response, role, day) {
            // console.log(dayTimestamp);
            return response.data.filter(
              (x) => x.role == role && x.hasOwnProperty(day)
            ).length;
          }

          function countPreRegDate(response, day) {
            return response.data.filter(
              (x) => x.role == "visitor" && x.hasOwnProperty(day)
            ).length;
          }

          function countEventRegDate(response, day) {
            return response.data.filter(
              (x) => x.role == "VISITOR" && x.hasOwnProperty(day)
            ).length;
          }

          function countRepVis(response, date) {
            // const specifiedDate = date.toLocaleDateString();
            //console.log(specifiedDate);
            let day2Count = 0;
            let day3Count = 0;
            let day4Count = 0;
            let repeateData = {};

            response.data.forEach((user) => {
              if (user.day2) {
                if (
                  (user.day1 === 1 && user.day2 === 1) ||
                  (user.day1 === 1 && user.day3 === 1) ||
                  (user.day1 === 1 && user.day4 === 1) ||
                  (user.day2 === 1 && user.day1 === 1) ||
                  (user.day2 === 1 && user.day3 === 1) ||
                  (user.day2 === 1 && user.day4 === 1) ||
                  (user.day3 === 1 && user.day1 === 1) ||
                  (user.day3 === 1 && user.day2 === 1) ||
                  (user.day3 === 1 && user.day4 === 1) ||
                  (user.day4 === 1 && user.day1 === 1) ||
                  (user.day4 === 1 && user.day2 === 1) ||
                  (user.day4 === 1 && user.day3 === 1)
                ) {
                  day2Count++;
                }
              }
              if (user.day3) {
                if (
                  (user.day1 === 1 && user.day2 === 1) ||
                  (user.day1 === 1 && user.day3 === 1) ||
                  (user.day1 === 1 && user.day4 === 1) ||
                  (user.day2 === 1 && user.day1 === 1) ||
                  (user.day2 === 1 && user.day3 === 1) ||
                  (user.day2 === 1 && user.day4 === 1) ||
                  (user.day3 === 1 && user.day1 === 1) ||
                  (user.day3 === 1 && user.day2 === 1) ||
                  (user.day3 === 1 && user.day4 === 1) ||
                  (user.day4 === 1 && user.day1 === 1) ||
                  (user.day4 === 1 && user.day2 === 1) ||
                  (user.day4 === 1 && user.day3 === 1)
                ) {
                  day3Count++;
                }
              }
              if (user.day4) {
                if (
                  (user.day1 === 1 && user.day2 === 1) ||
                  (user.day1 === 1 && user.day3 === 1) ||
                  (user.day1 === 1 && user.day4 === 1) ||
                  (user.day2 === 1 && user.day1 === 1) ||
                  (user.day2 === 1 && user.day3 === 1) ||
                  (user.day2 === 1 && user.day4 === 1) ||
                  (user.day3 === 1 && user.day1 === 1) ||
                  (user.day3 === 1 && user.day2 === 1) ||
                  (user.day3 === 1 && user.day4 === 1) ||
                  (user.day4 === 1 && user.day1 === 1) ||
                  (user.day4 === 1 && user.day2 === 1) ||
                  (user.day4 === 1 && user.day3 === 1)
                ) {
                  day4Count++;
                }
              }
            });
            repeateData["day2"] = day2Count;
            repeateData["day3"] = day3Count;
            repeateData["day4"] = day4Count;

            return repeateData;
          }

          const Day1TotalVip = roleCount(response, "VIP", "day1", "21-05-2024");
          const Day2TotalVip = roleCount(response, "VIP", "day2", "22-05-2024");
          const Day3TotalVip = roleCount(response, "VIP", "day3", "23-05-2024");
          const Day4TotalVip = roleCount(response, "VIP", "day4", "24-05-2024");
          //console.log(Day1TotalVip)
          const Day1TotalMedia = roleCount(
            response,
            "MEDIA",
            "day1",
            "05/21/2024"
          );
          const Day2TotalMedia = roleCount(
            response,
            "MEDIA",
            "day2",
            "05/22/2024"
          );
          const Day3TotalMedia = roleCount(
            response,
            "MEDIA",
            "day3",
            "05/23/2024"
          );
          const Day4TotalMedia = roleCount(
            response,
            "MEDIA",
            "day4",
            "05/24/2024"
          );

          const Day1TotalStudent = roleCount(
            response,
            "BULK_STUDENT",
            "day1",
            "05/21/2024"
          );
          const Day2TotalStudent = roleCount(
            response,
            "BULK_STUDENT",
            "day2",
            "05/22/2024"
          );
          const Day3TotalStudent = roleCount(
            response,
            "BULK_STUDENT",
            "day3",
            "05/23/2024"
          );
          const Day4TotalStudent = roleCount(
            response,
            "BULK_STUDENT",
            "day4",
            "05/24/2024"
          );

          const Day1TotalDelegation = roleCount(
            response,
            "DELEGATION",
            "day1",
            "05/21/2024"
          );
          const Day2TotalDelegation = roleCount(
            response,
            "DELEGATION",
            "day2",
            "05/22/2024"
          );
          const Day3TotalDelegation = roleCount(
            response,
            "DELEGATION",
            "day3",
            "05/23/2024"
          );
          const Day4TotalDelegation = roleCount(
            response,
            "DELEGATION",
            "day4",
            "05/24/2024"
          );

          const Day1TotalEc = roleCount(response, "EC", "day1", "05/21/2024");
          const Day2TotalEc = roleCount(response, "EC", "day2", "05/22/2024");
          const Day3TotalEc = roleCount(response, "EC", "day3", "05/23/2024");
          const Day4TotalEc = roleCount(response, "EC", "day4", "05/24/2024");

          const Day1TotalPreReg = countPreRegDate(response, "day1");
          const Day2TotalPreReg = countPreRegDate(response, "day2");
          const Day3TotalPreReg = countPreRegDate(response, "day3");
          const Day4TotalPreReg = countPreRegDate(response, "day4");

          const Day1TotalEventReg = countEventRegDate(response, "day1");
          const Day2TotalEventReg = countEventRegDate(response, "day2");
          const Day3TotalEventReg = countEventRegDate(response, "day3");
          const Day4TotalEventReg = countEventRegDate(response, "day4");

          const dayRepData = countRepVis(response);

          const convertedArray = [
            {
              day: 1,
              dayTotalVip: Day1TotalVip,
              dayTotalMedia: Day1TotalMedia,
              dayTotalStudent: Day1TotalStudent,
              dayTotalDelegation: Day1TotalDelegation,
              dayTotalEc: Day1TotalEc,
              dayTotalPreReg: Day1TotalPreReg,
              dayTotalEventReg: Day1TotalEventReg,
              dayTotalVistor: Day1TotalPreReg + Day1TotalEventReg,
              dayTotalReptVistor: 0,
              dayTotal:
                Day1TotalVip +
                Day1TotalMedia +
                Day1TotalStudent +
                Day1TotalDelegation +
                Day1TotalEc +
                (Day1TotalPreReg + Day1TotalEventReg),
            },
            {
              day: 2,
              dayTotalVip: Day2TotalVip,
              dayTotalMedia: Day2TotalMedia,
              dayTotalStudent: Day2TotalStudent,
              dayTotalDelegation: Day2TotalDelegation,
              dayTotalEc: Day2TotalEc,
              dayTotalPreReg: Day2TotalPreReg,
              dayTotalEventReg: Day2TotalEventReg,
              dayTotalVistor: Day2TotalPreReg + Day2TotalEventReg,
              dayTotalReptVistor: dayRepData.day2,
              dayTotal:
                Day2TotalVip +
                Day2TotalMedia +
                Day2TotalStudent +
                Day2TotalDelegation +
                Day2TotalEc +
                (Day2TotalPreReg + Day2TotalEventReg),
            },
            {
              day: 3,
              dayTotalVip: Day3TotalVip,
              dayTotalMedia: Day3TotalMedia,
              dayTotalStudent: Day3TotalStudent,
              dayTotalDelegation: Day3TotalDelegation,
              dayTotalEc: Day3TotalEc,
              dayTotalPreReg: Day3TotalPreReg,
              dayTotalEventReg: Day3TotalEventReg,
              dayTotalVistor: Day3TotalPreReg + Day3TotalEventReg,
              dayTotalReptVistor: dayRepData.day3,
              dayTotal:
                Day3TotalVip +
                Day3TotalMedia +
                Day3TotalStudent +
                Day3TotalDelegation +
                Day3TotalEc +
                (Day3TotalPreReg + Day3TotalEventReg),
            },
            {
              day: 4,
              dayTotalVip: Day4TotalVip,
              dayTotalMedia: Day4TotalMedia,
              dayTotalStudent: Day4TotalStudent,
              dayTotalDelegation: Day4TotalDelegation,
              dayTotalEc: Day4TotalEc,
              dayTotalPreReg: Day4TotalPreReg,
              dayTotalEventReg: Day4TotalEventReg,
              dayTotalVistor: Day4TotalPreReg + Day4TotalEventReg,
              dayTotalReptVistor: dayRepData.day4,
              dayTotal:
                Day4TotalVip +
                Day4TotalMedia +
                Day4TotalStudent +
                Day4TotalDelegation +
                Day4TotalEc +
                (Day4TotalPreReg + Day4TotalEventReg),
            },
          ];
          //console.log(convertedArray);
          setData(convertedArray);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getTableCountData();
  }, []);

  // CSS Styling
  class tabularDataStyles {
    static get tabularDataContainer() {
      return {
        background: "#ffffff",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }
  return (
    <Container maxWidth="xl" style={tabularDataStyles.tabularDataContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Day</TableCell>
            <TableCell align="center">VIP</TableCell>
            <TableCell align="center">Media</TableCell>
            <TableCell align="center">Students</TableCell>
            <TableCell align="center">Delegation</TableCell>
            <TableCell align="center">Ec</TableCell>
            <TableCell align="center">Pre Event Registration</TableCell>
            <TableCell align="center">Event Days Registratio</TableCell>
            <TableCell align="center">Total Visitors</TableCell>
            <TableCell align="center">Repeat Visitors</TableCell>
            <TableCell align="center">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.day}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="center">
                {row.day}
              </TableCell>
              <TableCell align="center">{row.dayTotalVip}</TableCell>
              <TableCell align="center">{row.dayTotalMedia}</TableCell>
              <TableCell align="center">{row.dayTotalStudent}</TableCell>
              <TableCell align="center">{row.dayTotalDelegation}</TableCell>
              <TableCell align="center">{row.dayTotalEc}</TableCell>
              <TableCell align="center">{row.dayTotalPreReg}</TableCell>
              <TableCell align="center">{row.dayTotalEventReg}</TableCell>
              <TableCell align="center">{row.dayTotalVistor}</TableCell>
              <TableCell align="center">{row.dayTotalReptVistor}</TableCell>

              <TableCell align="center">{row.dayTotal}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
}
