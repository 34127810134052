import React from "react";
import * as XLSX from "xlsx/xlsx.mjs";

const exportToExcel = (data, fileName) => {
  function extractDate(isoString) {
    const dateObj = new Date(isoString);
    return dateObj.toLocaleDateString("en-GB"); // en-GB format is dd/mm/yyyy
  }

  if (fileName === "sampleData.xlsx") {
    const transformedData = [];
    // debugger;

    data.forEach((item) => {
      if (item.day1) {
        transformedData.push(item);
      }
      if (item.day2) {
        transformedData.push(item);
      }
      if (item.day3) {
        transformedData.push(item);
      }
      if (item.day3) {
        transformedData.push(item);
      }
    });
    console.log(transformedData);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(transformedData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  } else {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  }
};

export default exportToExcel;
