
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChartDepartmentWise = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.labels.length > 0 &&
      props.data.datasets.length > 0
    ) {
      const ctx = chartRef.current.getContext("2d");
      const monthlyChart = new Chart(ctx, {
        type: "doughnut",
        data: props.data,
        plugins: [ChartDataLabels],
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                color: 'black',
                font: {
                  size: 10
                },
                formatter: function(value) {
                  return value;
                }    
              },
              legend: {
                position: 'right',
                labels: {
                  font: {
                    size: '10'
                  }
                }
              }
            }    
        }
      })

      return () => {
        monthlyChart.destroy();
      };
    }
  }, [props.data]);

  return (
        <canvas
            ref={chartRef}
            width="100%"
            height="450px"
            style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}
        />
  );
};

export default PieChartDepartmentWise;
