
import Card from "./card";
import Container from "@mui/material/Container";



const Stats = (prop) => {
 
  // CSS Styling
  class statsStyles {
    static get statsContainer() {
      return {
        width: '100%',
        height: 'auto',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '15px'
      }
    }
  }

  return (
    <Container maxWidth="xl" style={statsStyles.statsContainer}>
       <Card cardData={prop.cardData}/>
    </Container>
  )
}

export default Stats;