import React,{useState,useEffect} from "react";
import CommonHdr from "../commonHdr";
import PieChartDepartmentWise from "./pieChartDepartmentWise";
import { Container } from "@mui/material";
import axios from "axios";
import { VISITOR_DEPERTMENTWISE_GRAPH_API } from "../../constants/constants";
import exportToExcel from '../ExportExcel/ExportExcel.js';

const DepartmentWise = () =>{

    const [departmentWise, setDepartmentWise] = useState([]);
    
    useEffect(() => {
      const getDepartmentWiseData = async () => {
  
        try {
          const response = await axios.get(
            `${VISITOR_DEPERTMENTWISE_GRAPH_API}`
          );
  
          if (response.data !== null) {
            setDepartmentWise(response.data)
          }
          
        } catch (error) {
          alert('Error'+error)
        }
      };
      getDepartmentWiseData();
    },[]);

  // Static Data For Bar Chart
  const departmentWisedata = {
      labels:departmentWise.map((val)=> val._id),
      datasets: [{
        label: "",
        data: departmentWise.map((val)=> val.totalUsers),
        backgroundColor: [
          '#118DFF',
          '#12239E',
          '#E66C37',
          '#6B007B',
          '#E044A7',
          '#744EC2',
          '#D9B300',
          '#D64550',
          '#197278',
          '#1AAB40',
          '#15C6F4',
          '#4092FF',
          '#FFA058',
          '#BE5DC9',
          '#ACE2E1',
          '#F7EEDD',
          '#008DDA',
          '#FFF455'
        ],
        hoverOffset: 10,
        datalabels: {
          align: 'start',
          anchor: 'end'
        }  
      }]
    };
    const handleClick = () => {
      exportToExcel(departmentWise,'DepartmentWise.xlsx')
    };
  // CSS Styling
  class departmentWiseStyles {
    static get departmentWiseContainer() {
      return {
        background: '#ffffff',
        height: '500px',
        width: '100%',
        marginTop: '20px',
        borderRadius: '8px'
      }
    }
}

  return (
      <Container maxWidth="xl" style={departmentWiseStyles.departmentWiseContainer}>
          <CommonHdr hdr={"Department wise"} onClick={handleClick}/>
          <div style={{width: 'auto', height: 'auto'}}>
              <PieChartDepartmentWise data={departmentWisedata} />
          </div>
      </Container>
  )
}

export default DepartmentWise;