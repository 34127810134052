import React, { useState, useEffect } from "react";
import CommonHdr from "../commonHdr";
import DonutChartHourlyFootfall from "./donutChartUserTypeFootfall";
import { Container } from "@mui/material";
import axios from "axios";
import { ROLE_WISE_API } from "../../constants/constants.js";

import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const UserTypeFootfall = () => {
  const dates = ["2024-05-25", "2024-05-26", "2024-05-27", "2024-05-28"];
  const [footfallCounts, setFootfallCounts] = useState({});
  const [selectedDate, setSelectedDate] = useState(dates[2]);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  useEffect(() => {
    const counts = {};
    const normalizeRole = (role) => {
      const normalizedRole = role.toLowerCase();
      if (normalizedRole === "visitor" || normalizedRole === "visitor") {
        return "visitor";
      }
      if (normalizedRole === "student" || normalizedRole === "bulk_student") {
        return "student";
      }
      return normalizedRole;
    };
    const processDayWiseData = (hourlyData) => {
      dates.forEach((date, index) => {
        const dateObj = new Date(selectedDate);
        const nextDay = new Date(dateObj);
        nextDay.setDate(dateObj.getDate() + 1);
        const timestampKey = `day${index + 1}timestamp`;
        const dayData = hourlyData.filter((data, index) => {
          if (data.role !== "EXHIBITOR" && data.role !== "SERVICE") {
            const createdDate = new Date(data[timestampKey]);
            return createdDate >= dateObj && createdDate < nextDay;
          }
        });

        dayData.forEach((dat1) => {
          const normalizedRole = normalizeRole(dat1.role);
          const userType = normalizedRole;
          //console.log(dat1);
          if (counts[userType]) {
            counts[userType] += 1;
          } else {
            counts[userType] = 1;
          }
        });
      });
      // console.log(counts);
      setFootfallCounts(counts);
    };

    const getDayWiseRoleData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(ROLE_WISE_API);
        if (response.data !== null) {
          processDayWiseData(response.data);
        }
      } catch (error) {
        alert("Error: " + error);
      } finally {
        setLoading(false);
      }
    };
    getDayWiseRoleData();
    // const interval = setInterval(getDayWiseRoleData, 300000); // Fetch every 5 min
    // return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [selectedDate]);
  // Static Data For Bar Chart
  // Static Data For Bar Chart
  const userTypeFootfalldata = {
    labels: Object.keys(footfallCounts),
    datasets: [
      {
        label: "",
        data: Object.values(footfallCounts),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#CC65FE",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#CC65FE",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
        ],
        hoverOffset: 10,
        datalabels: {
          align: "start",
          anchor: "end",
        },
      },
    ],
  };

  // CSS Styling
  class userTypeFootfallStyles {
    static get userTypeFootfallContainer() {
      return {
        background: "#ffffff",
        height: "350px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={userTypeFootfallStyles.userTypeFootfallContainer}
    >
      <CommonHdr hdr={"Footfall Count By User Type"} />
      <FormControl size="small" fullWidth style={{ marginBottom: "20px" }}>
        <InputLabel id="date-select-label">Select Date</InputLabel>
        <Select
          labelId="date-select-label"
          id="date-select"
          value={selectedDate}
          label="Select Date"
          onChange={handleDateChange}
        >
          {dates.map((date) => (
            <MenuItem key={date} value={date}>
              {date}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {loading && <CircularProgress color="inherit" />}
      <div style={{ width: "auto", height: "auto" }}>
        <DonutChartHourlyFootfall data={userTypeFootfalldata} />
      </div>
    </Container>
  );
};

export default UserTypeFootfall;
