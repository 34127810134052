import React, { useEffect, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PrintIcon from "@mui/icons-material/Print";
import InfoIcon from "@mui/icons-material/Info";
import SummarizeIcon from "@mui/icons-material/Summarize";
import BadgeIcon from "@mui/icons-material/Badge";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Container from "@mui/material/Container";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { Link } from "react-router-dom";
import { colors } from "@mui/material";
import exportToExcel from "../ExportExcel/ExportExcel.js";
import { EXPORT_EXCEL_API } from "../../constants/constants.js";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [exportExcelData, setExportExcelData] = useState([]);
  useEffect(() => {
    const getExportExcelData = async () => {
      try {
        const response = await axios.get(`${EXPORT_EXCEL_API}`);

        if (response.data !== null) {
          setExportExcelData(response.data);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getExportExcelData();
  }, []);

  const handleExportClick = () => {
    console.log("test");
    exportToExcel(exportExcelData, "sampleData.xlsx");
  };

  const handleLogout = () => {
    console.log("logout");
    localStorage.removeItem("isAuthenticated");
    navigate("/");
    window.location.reload();
  };

  // Sidebar Data
  const sideBarData = [
    {
      title: "Registration",
      childTitle: [],
      icon: <DashboardIcon />,
      path: " ",
    },
    {
      title: "Foot Fall Data",
      childTitle: [
        // {childPath:"#",childTitle:"Registration Desk"},
        // { childPath: "footfallData", childTitle: "Foot Fall Data" },
      ],
      icon: <SummarizeIcon />,
      path: "footfallData",
    },
    {
      title: "Export Excel",
      childTitle: [],
      icon: <ImportExportIcon />,
      path: "#",
      onClick: handleExportClick,
    },
    {
      title: "Logout",
      childTitle: [],
      icon: <LogoutIcon />,
      path: "#",
      onClick: handleLogout,
    },
  ];

  // CSS Styling
  class sidebarStyles {
    static get sidebarContainer() {
      return {
        position: "relative",
        marginTop: "20px",
        marginLeft: "10px",
        height: "100vh",
        width: "100%",
        background: "var(--dark-color)",
        borderRadius: "8px",
      };
    }

    static get sidebarList() {
      return {
        height: "auto",
        padding: "0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "50px",
        paddingBottom: "50px",
        cursor: "pointer",
      };
    }

    static get parentRow() {
      return {
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--sidebar-title-color)",
        margin: "15px",
      };
    }

    static get parentIcon() {
      return {
        flex: "20%",
        display: "grid",
        placeItems: "center",
      };
    }

    static get parentTitle() {
      return {
        flex: "80%",
        fontFamily: '"DM Sans", sans-serif',
        fontWeight: "500",
        fontSize: "16px",
      };
    }

    static get childList() {
      return {
        height: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      };
    }

    static get childRow() {
      return {
        width: "80%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--sidebar-title-color)",
        margin: "3px",
      };
    }

    static get childIcon() {
      return {
        flex: "30%",
        display: "grid",
        placeItems: "center",
      };
    }

    static get childTitle() {
      return {
        display: "grid",
        flex: "70%",
        fontfamily: '"DM Sans", sans-serif',
        fontweight: "500",
        fontsize: "14px",
      };
    }
  }

  return (
    <Container maxWidth="xl" style={sidebarStyles.sidebarContainer}>
      <ul style={sidebarStyles.sidebarList}>
        {sideBarData.map((element) => {
          return (
            <li
              key={element.title}
              className="row"
              style={sidebarStyles.parentRow}
            >
              <div style={sidebarStyles.parentIcon}>{element.icon}</div>

              <div style={sidebarStyles.parentTitle}>
                <Link
                  to={element.path}
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={
                    element.onClick
                      ? element.onClick
                      : () => navigate(element.path)
                  }
                >
                  {element.title}
                </Link>
              </div>

              {element.childTitle.map((position, i) => {
                return (
                  <ul style={sidebarStyles.childList}>
                    <li
                      key={position.childTitle}
                      className="row gx-0"
                      style={sidebarStyles.childRow}
                    >
                      <div style={sidebarStyles.childIcon}>
                        {<ArrowRightIcon />}
                      </div>
                      <div style={sidebarStyles.childTitle}>
                        <Link
                          to={position.childPath}
                          style={{ textDecoration: "none", color: "#fff" }}
                        >
                          {position.childTitle}
                        </Link>
                      </div>
                    </li>
                  </ul>
                );
              })}
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Sidebar;
