import NotificationsIcon from "@mui/icons-material/Notifications";
import imtex2024 from "../../assets/PMTX 2024 - Logo.png";
import imtThumbnail from "../../assets/IMTMA-favicon.png";
import imtIcon from "../../assets/IMTMA.png";
import Container from "@mui/material/Container";

const Header = () => {
  // CSS Styling
  class hdrContainerStyles {
    static get hdrContainer() {
      return {
        width: "100%",
        height: "60px",
        marginTop: "10px",
        borderRadius: "8px",
        background: "#ffffff",
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "0px 5px 12px rgba(0,0,0,0.1)",
      };
    }

    static get hdrLeft() {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        paddingLeft: "20px",
      };
    }

    static get hdrRight() {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        paddingRight: "20px",
      };
    }

    static get circleNotification() {
      return {
        height: "40px",
        width: "40px",
        background: "#eeeded",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      };
    }

    static get notification() {
      return {
        cursor: "pointer",
      };
    }

    static get imtThumbnail() {
      return {
        background: "#ffffff",
        height: "30px",
        width: "30px",
        borderRadius: "50%",
      };
    }
  }

  return (
    <Container maxWidth="xl" style={hdrContainerStyles.hdrContainer}>
      <div style={hdrContainerStyles.hdrLeft}>
        <img
          src={imtex2024}
          alt="IMTEX-2024-logo"
          style={{ height: "90%", width: "90%" }}
        />
        {/* <img
          src={imtIcon}
          alt="IMT-icon"
          style={{ height: "90%", width: "90%" }}
        /> */}
      </div>
      <div style={hdrContainerStyles.hdrRight}>
        {/* <div style={hdrContainerStyles.circleNotification}>
          <div style={hdrContainerStyles.notification}>
            <NotificationsIcon />
          </div>
        </div> */}
        <div style={hdrContainerStyles.circleNotification}>
          <img
            src={imtex2024}
            alt="IMT-thumbnail"
            style={hdrContainerStyles.imtThumbnail}
          />
        </div>
      </div>
    </Container>
  );
};

export default Header;
