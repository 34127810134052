export const LOCAL = "local";
export const PRODUCTION = "production";
export const state = PRODUCTION;

export const API_ENDPOINT =
  state === PRODUCTION
    ? "https://apis.expoplanner.in/"
    : "http://localhost:5002/";

export const VISITOR_ONSITE_API = `${API_ENDPOINT}user/a/get-all-visitors-tmp-xxx`;
export const VISITOR_API = `${API_ENDPOINT}visitor/`;
export const VISITOR_METRICS_API = `${API_ENDPOINT}user/get-monthwise-visitors-current-year?dB=pmtx2024-imtma`;
export const VISITOR_COUNT_API = `${API_ENDPOINT}user/get-totals-user?dB=pmtx2024-imtma`;
export const VISITOR_INDUSTRYWISE_GRAPH_API = `${API_ENDPOINT}user/get-industrywise-users-count?dB=pmtx2024-imtma`;
export const VISITOR_DEPERTMENTWISE_GRAPH_API = `${API_ENDPOINT}user/get-departmentwise-users-count?dB=pmtx2024-imtma`;
export const FOOTFULL_COUNT_API = `${API_ENDPOINT}user/get-users-group-count?dB=pmtx2024-imtma`;
export const COUNTRYWISE_API = `${API_ENDPOINT}user/get-users-group-countrywise?dB=pmtx2024-imtma`;
export const DAY_WISE_API = `${API_ENDPOINT}user/scanned-users-footfall?pb=pmtx2024-imtma`;
export const ROLE_WISE_API = `${API_ENDPOINT}user/scanned-users-footfall?pb=pmtx2024-imtma`;
export const HOURLY_WISE_API = `${API_ENDPOINT}user/scanned-users-footfall?pb=pmtx2024-imtma`;
export const EXPORT_EXCEL_API = `${API_ENDPOINT}user/scanned-users-footfall?pb=pmtx2024-imtma`;
export const TABLE_COUNT_API = `${API_ENDPOINT}user/scanned-users-footfall?pb=pmtx2024-imtma`;
