import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import IosShareIcon from "@mui/icons-material/IosShare";
import BarChartSessionCountries from "./barChartSessionCountries.js";
import Container from "@mui/material/Container";
import axios from "axios";
import { COUNTRYWISE_API } from "../../constants/constants";
import exportToExcel from "../ExportExcel/ExportExcel.js";

const CountrySession = () => {
  
  function getMax(array) {
    // Use reduce to find the maximum age
    return array.reduce((max, obj) => {
      return Math.max(max, obj.totalUsers);
    }, -Infinity);
  }

  const [countryWiseMetric, setCountryWise] = useState([]);
  const [maximum, setMax] = useState();
  /// console.log(industryWiseMetric)
  useEffect(() => {
    const getCountryWiseData = async () => {
      try {
        const response = await axios.get(`${COUNTRYWISE_API}`);
        if (response.data !== null) {
          //console.log(response.data)
          const getCountry = response.data.filter((obj) => {
            return Object.values(obj).every((value) => value !== null);
          });
          setCountryWise(getCountry);
          const max = getMax(getCountry);
          setMax(max);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getCountryWiseData();
  }, []);

  // Static Data For Bar Chart
  const countriesSessionData = {
    labels: countryWiseMetric.map((val) => val._id),
    datasets: [
      {
        axis: "y",
        label: "",
        barThickness: 35,
        borderRadius: 5,
        backgroundColor: [
          "#49abe0",
          "#49abe0",
          "#49abe0",
          "#49abe0",
          "#f27c63",
          "#49abe0",
          "#49abe0",
          "#49abe0",
          "#49abe0",
          "#49abe0",
        ],
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: countryWiseMetric.map((val) => val.totalUsers),
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
  };

  // CSS Styling
  class countrySessionStyles {
    static get countrySessionContainer() {
      return {
        background: "#ffffff",
        height: "580px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }

    static get countrySessionHdr() {
      return {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      };
    }

    static get hdrTitle() {
      return {
        fontFamily: '"DM Sans",sans-serif',
        fontSize: "16px",
        color: "var(--title-color)",
        paddingLeft: "10px",
        paddingTop: "10px",
      };
    }

    static get hdrRight() {
      return {
        display: "flex",
        gap: "20px",
        paddingTop: "10px",
        paddingRight: "10px",
      };
    }

    static get exportBtn() {
      return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        fontSize: "12px",
        color: "var(--dark-color)",
        background: "var(--btn-bg-color)",
        fontFamily: '"DM Sans",sans-serif',
        border: "none",
        borderRadius: "8px",
      };
    }

    static get timeFrameFilterContainer() {
      return {
        display: "flex",
        gap: "5px",
        fontSize: "12px",
        color: "var(--dark-color)",
      };
    }

    static get timeFrameFilterBtn() {
      return {
        fontSize: "12px",
        color: "var(--dark-color)",
        background: "var(--btn-bg-color)",
        border: "none",
      };
    }
  }

  const handleClick = () => {
    exportToExcel(countryWiseMetric, "countryWise.xlsx");
  };
  return (
    <Container
      maxWidth="xl"
      style={countrySessionStyles.countrySessionContainer}
    >
      <div style={countrySessionStyles.countrySessionHdr}>
        <h5 style={countrySessionStyles.hdrTitle}>Sessions By Countries</h5>
        <div style={countrySessionStyles.hdrRight}>
          <Button style={countrySessionStyles.exportBtn} onClick={handleClick}>
            <IosShareIcon fontSize="extra-small" />
            Export
          </Button>
          {/* <div style={countrySessionStyles.timeFrameFilterContainer}>
            <Button style={countrySessionStyles.timeFrameFilterBtn}>All</Button>
            <Button style={countrySessionStyles.timeFrameFilterBtn}>1M</Button>
            <Button style={countrySessionStyles.timeFrameFilterBtn}>6M</Button>
          </div> */}
        </div>
      </div>
      <div style={{ width: "auto", height: "auto" }}>
        <BarChartSessionCountries
          data={countriesSessionData}
          maximum={maximum}
        />
      </div>
    </Container>
  );
};

export default CountrySession;
