import React from 'react';
import {
    Header,
    Footer,
    Sidebar
  } from "../components";
  import { Outlet } from "react-router-dom";
  import {Box, Paper, Grid } from "@mui/material";
import StickyBox from "react-sticky-box";

const Layout = () => {
  return (
    <>
    

    <Header />
    <Box sx={{ flexGrow: 1 }}>
         <Grid container spacing={2}>
            <Grid item xs={3}>
              <StickyBox>
                <Sidebar /> 
              </StickyBox>
            </Grid>
          
       
          <Grid item xs={9}>
              <Outlet />
          </Grid>
        </Grid>
    </Box>
    <Footer/>
    </>
  )
}

export default Layout
