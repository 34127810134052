import BarChartDesignationWise from "./barChartDesignationWise.js";
import CommonHdr from "../commonHdr";
import { Container } from "@mui/material";
import axios from "axios";
import { VISITOR_ONSITE_API } from "../../constants/constants";
import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const DesignationWise = () => {
  const [countSixOneDesignations, setSixOneDesignations] = useState();
  const [countSixTwoDesignations, setSixTwoDesignations] = useState();
  const [countSixThreeDesignations, setSixThreeDesignations] = useState();
  const [countSixFourDesignations, setSixFourDesignations] = useState();
  const [countSixFiveDesignations, setSixFiveDesignations] = useState();
  const [loading, setLoading] = useState(false);

  const groupDataByDesignation = (data) => {
    const designationCounts = {};

    // Iterate through each user data
    data.forEach((user) => {
      const { designation } = user;

      // Check if designation is included in the provided list of designations
      if (designation.includes(designation)) {
        // Check if designation is already a key in designationCounts
        if (!designationCounts[designation]) {
          // If not, initialize count to 1
          designationCounts[designation] = 1;
        } else {
          // If yes, increment count by 1
          designationCounts[designation]++;
        }
      }
    });

    return designationCounts;
  };

  useEffect(() => {
    const getDesignationWiseData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${VISITOR_ONSITE_API}`);
        if (response.data !== null) {
          //  console.log(response.data.data);
          const designationWiseUser = groupDataByDesignation(
            response.data.data
          );
          //console.log(designationWiseUser);
          const sumOfSixOneDesignations =
            (designationWiseUser["Chairman"] || 0) +
            (designationWiseUser["Managing Director"] || 0) +
            (designationWiseUser["CEO"] || 0) +
            (designationWiseUser["Country Head"] || 0) +
            (designationWiseUser["President"] || 0) +
            (designationWiseUser["ED"] || 0);
          setSixOneDesignations(sumOfSixOneDesignations);
          const sumOfSixTwoDesignations =
            (designationWiseUser["Vice President"] || 0) +
            (designationWiseUser["Sr. Vice President"] || 0) +
            (designationWiseUser["CTO"] || 0) +
            (designationWiseUser["COO"] || 0) +
            (designationWiseUser["CFO"] || 0) +
            (designationWiseUser["Sr. Director"] || 0);
          setSixTwoDesignations(sumOfSixTwoDesignations);
          const sumOfSixThreeDesignations =
            (designationWiseUser["Director"] || 0) +
            (designationWiseUser["Deputy Director"] || 0) +
            (designationWiseUser["General Manager"] || 0) +
            (designationWiseUser["Manager"] || 0);
          setSixThreeDesignations(sumOfSixThreeDesignations);
          const sumOfSixFourDesignations =
            (designationWiseUser["Technician"] || 0) +
            (designationWiseUser["Machine Operator"] || 0) +
            (designationWiseUser["Engineer"] || 0) +
            (designationWiseUser["Production/ Plant/ Technical Head"] || 0);
          setSixFourDesignations(sumOfSixFourDesignations);
          const sumOfSixFiveDesignations =
            (designationWiseUser["Others-Trainees"] || 0) +
            (designationWiseUser["Consultant"] || 0) +
            (designationWiseUser["Executive"] || 0) +
            (designationWiseUser["Partner"] || 0) +
            (designationWiseUser["Advisor"] || 0) +
            (designationWiseUser["Programmer"] || 0) +
            (designationWiseUser["Contractor"] || 0);
          setSixFiveDesignations(sumOfSixFiveDesignations);
        }
      } catch (error) {
        alert("Error" + error);
      } finally {
        setLoading(false);
      }
    };
    getDesignationWiseData();
  }, []);

  // Static Data For Bar Chart
  const designationWiseData = {
    labels: [
      ["Chairman", "MD", "CEO", "Country Head", "President", "ED"],
      ["VP", "Sr.VP", "CTO", "COO", "CFO", "Sr.Director"],
      ["Director", "Deputy Director", "GM", "Manager"],
      [
        "Technician",
        "Machine Operator",
        "Engineer",
        "Production/Plant/",
        "Technical Head",
      ],
      [
        "Others-Trainees",
        "Consultant",
        "Executive",
        "Partner",
        "Advisor",
        "Programmer",
        "Contractor",
      ],
    ],
    datasets: [
      {
        label: "",
        barThickness: 70,
        backgroundColor: "#ED731F",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: [
          countSixOneDesignations,
          countSixTwoDesignations,
          countSixThreeDesignations,
          countSixFourDesignations,
          countSixFiveDesignations,
        ],
        datalabels: {
          align: "start",
          anchor: "end",
        },
      },
    ],
  };

  // CSS Styling
  class designationWiseStyles {
    static get designationWiseContainer() {
      return {
        background: "#ffffff",
        height: "500px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={designationWiseStyles.designationWiseContainer}
    >
      <CommonHdr hdr={"Designation wise"} />
      {loading && <CircularProgress color="inherit" />}
      <div style={{ width: "auto", height: "auto" }}>
        <BarChartDesignationWise data={designationWiseData} />
      </div>
    </Container>
  );
};

export default DesignationWise;
