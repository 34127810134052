// import { Header, Footer } from "./components";
import React, { useState } from "react";
import Login from "./components/Login/Login";
import SecurityScan from "./components/badgeprinter/SecurityScan";
import ServiceBadge from "./components/Service/ServiceBadge";
import SpotUser from "./components/SportUser/SportUser";
import SpotRegistration from "./components/SportUser/SportRegistration";
import ScanService from "./components/Service/ScanService";
import ScanBadge from "./components/badgeprinter/ScanBadge";
import PreviewFormContainer from "./components/Registration/previewFormContainer";
import FootfallDataReport from "./pages/footfallDataReport";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RegistrationDeskReport from "./pages/registrationDeskReport";
import "./App.css";
import Layout from "./components/Layout";
import Container from "@mui/material/Container";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const queryClient = new QueryClient();
  const [currentLogin, setCurrentLogin] = useState(false);

  return (
    <Container maxWidth="xl" className="app">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* <Header /> */}
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <Login />
                </PublicRoute>
              }
            />
            <Route path="/dashboard" element={<Layout />}>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <RegistrationDeskReport />
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="visitor-registration"
                element={<PreviewFormContainer />}
              /> */}
              {/* <Route path="spot-registration" element={<SpotRegistration />} />
              <Route path="onsite-users" element={<SpotUser />} />
              <Route path="service-scan" element={<ScanService />} />
              <Route path="service-badge" element={<ServiceBadge />} />
              <Route path="scan-badge" element={<ScanBadge />} />
              <Route path="security-scan" element={<SecurityScan />} /> */}
              <Route
                path="footfallData"
                element={
                  <PrivateRoute>
                    <FootfallDataReport />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Login />} />
            </Route>
          </Routes>
          {/* <Footer/> */}
        </BrowserRouter>
      </QueryClientProvider>
    </Container>
  );
}
const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  return isAuthenticated ? <Navigate to="/dashboard" /> : children;
};
export default App;
