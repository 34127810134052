import React, { useEffect, useState } from "react";
import LineChartHourlyFootfall from "./lineChartHourlyFootfall";
import CommonHdr from "../commonHdr";
import { Container } from "@mui/material";
import axios from "axios";
import { HOURLY_WISE_API } from "../../constants/constants.js";
import CircularProgress from "@mui/material/CircularProgress";

const HourlyFootfallLine = (props) => {
  const dates = ["2024-05-25", "2024-05-26", "2024-05-27", "2024-05-28"];
  const [hourlyDataByDate, setHourlyDataByDate] = useState({});
  const [loading, setLoading] = useState(false);

  const getHourLabel = (hour) => {
    const labels = {
      7: "7am-8am",
      8: "8am-9am",
      9: "9am-10am",
      10: "10am-11am",
      11: "11am-12pm",
      12: "12pm-1pm",
      13: "1pm-2pm",
      14: "2pm-3pm",
      15: "3pm-4pm",
      16: "4pm-5pm",
      17: "5pm-6pm",
      18: "6pm-7pm",
    };
    return labels[hour];
  };

  useEffect(() => {
    const processHourlyData = (hourlyData) => {
      const hourlyDat = {};
      //debugger;
      dates.forEach((date, index) => {
        const dateObj = new Date(date);
        const nextDay = new Date(dateObj);
        nextDay.setDate(dateObj.getDate() + 1);
        const timestampKey = `day${index + 1}timestamp`;
        const dayData = hourlyData.filter((data) => {
          if (data.role !== "EXHIBITOR" && data.role !== "SERVICE") {
            const createdDate = new Date(data[timestampKey]);
            return createdDate >= dateObj && createdDate < nextDay;
          }
        });
        //console.log(dayData)
        const hourlyCounts = {
          "7am-8am": 0,
          "8am-9am": 0,
          "9am-10am": 0,
          "10am-11am": 0,
          "11am-12pm": 0,
          "12pm-1pm": 0,
          "1pm-2pm": 0,
          "2pm-3pm": 0,
          "3pm-4pm": 0,
          "4pm-5pm": 0,
          "5pm-6pm": 0,
          "6pm-7pm": 0,
        };

        dayData.forEach((dat1) => {
          const hour = new Date(dat1[timestampKey]).getHours();
          //console.log(hour);
          const hourLabel = getHourLabel(hour);
          //console.log(hourLabel);
          if (hourLabel) {
            hourlyCounts[hourLabel]++;
          }
        });

        hourlyDat[date] = hourlyCounts;
        //console.log(hourlyDat);
      });
      // console.log(hourlyDat);
      setHourlyDataByDate(hourlyDat);
    };

    const getHourlyWiseData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(HOURLY_WISE_API);
        if (response.data !== null) {
          processHourlyData(response.data);
        }
      } catch (error) {
        alert("Error: " + error);
      } finally {
        setLoading(false);
      }
    };
    getHourlyWiseData();
    // const interval = setInterval(getHourlyWiseData, 10000); // Fetch every 5 seconds
    // return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  //console.log(hourlyDataByDate);
  const colors = [
    "rgba(255, 99, 132, 0.5)", // Red
    "rgba(153, 102, 255, 0.5)", // Purple
    "rgba(255, 159, 64, 0.5)", // Orange
    "rgba(75, 192, 192, 0.5)", // Green
    "rgba(54, 162, 235, 0.5)", // Blue
    "rgba(255, 206, 86, 0.5)", // Yellow
    "rgba(231, 233, 237, 0.5)", // Grey
    "rgba(201, 203, 207, 0.5)", // Dark Grey
    "rgba(153, 102, 255, 0.5)", // Light Purple
    "rgba(255, 99, 132, 0.5)", // Light Red
  ];

  // Static Data For Bar Chart
  const hourlyFootfallData = {
    labels: [
      "7am-8am",
      "8am-9am",
      "9am-10am",
      "10am-11am",
      "11am-12pm",
      "12pm-1pm",
      "1pm-2pm",
      "2pm-3pm",
      "3pm-4pm",
      "4pm-5pm",
      "5pm-6pm",
      "6pm-7pm",
    ],
    datasets: Object.keys(hourlyDataByDate).map((date, idx) => ({
      label: date,
      data: Object.values(hourlyDataByDate[date]),
      borderColor: colors[idx % colors.length],
      backgroundColor: colors[idx % colors.length],
    })),
  };

  // CSS Styling
  class hourlyFootfallStyles {
    static get hourlyFootfallContainer() {
      return {
        background: "#ffffff",
        height: "350px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={hourlyFootfallStyles.hourlyFootfallContainer}
    >
      <CommonHdr
        hdr="Day Wise Hourly Footfall"
        toggleBtn={true}
        // exportBtn={true}
        setToggle={props.setToggle}
        toggle={props.toggle}
      />
      {loading && <CircularProgress color="inherit" />}
      <div style={{ width: "auto", height: "auto" }}>
        <LineChartHourlyFootfall data={hourlyFootfallData} />
      </div>
    </Container>
  );
};

export default HourlyFootfallLine;
