import React, { useRef, useState } from "react";
import { Tabs, Tab, Box, Typography, ThemeProvider } from "@mui/material";
import { ExpoTheme } from "../theme/theme";
import BackBtn from "../widgets/BackBtn";
import InputPage from "./InputPage";
import { LoadingButton } from "@mui/lab";
import { saveAs } from "file-saver";
import axios from "axios";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
// import { BadgeToPrint } from "../dashboard/visitor/BadgeToPrint";
import { API_ENDPOINT } from "../../constants/constants";
import * as XLSX from "xlsx";

import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
export const SPOT_REG_CATEGORIES = [
  { label: "VISITOR", content: "Visitor content goes here." },
  { label: "EXHIBITOR", content: "Exhibitor content goes here." },
  { label: "DELEGATION", content: "Delegation content goes here." },
  { label: "VIP", content: "VIP content goes here." },
  { label: "EC", content: "EC content goes here." },
  { label: "SERVICE", content: "Service content goes here." },
  { label: "MEDIA", content: "Media content goes here." },
  { label: "STUDENT", content: "Student content goes here." },
];
class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;
    const vCard = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `NOTE: Barcode ID: ${row.barcode_id}`,
      `N:${row.lastName};${row.firstName};;;`,
      `FN:${row.title} ${row.firstName} ${row.lastName}`,
      `ORG:${row.companyName.value}`,
      `TITLE:${row.designation}`,
      `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
      "END:VCARD",
    ].join("\n");
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.8cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
          >
            {row.firstName.toUpperCase()} {row.lastName.toUpperCase()}
          </div>
          <div style={{ fontSize: "19px", marginTop: "12px" }}>
            {row.companyName.toUpperCase()}
          </div>
        </div>
        <div style={{ marginTop: "16px" }}>
          <QRCode value={vCard} size={130} />
        </div>
      </div>
    );
  }
}
const SpotRegistration = () => {
  const [registering, setRegistering] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const printRef = useRef();
  const [state, setState] = useState({
    title: "",
    firstName: "",
    lastName: "",
    designation: "",
    phone: "",
    email: "",
    companyName: "",
    address: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",
    pin: "",
    website: "",
    telephone: "",
  });
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleStateChange = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleDownload = async () => {
    const fileURL =
      "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/excel_template_for_spot.xlsx";
    saveAs(fileURL, "template_spot_reg.xlsx");
  };

  const onClickSubmit = async () => {
    if (state.title === "") {
      let titleField = document.getElementsByName("title")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.firstName === "") {
      toast("Please enter First Name");
      let titleField = document.getElementsByName("firstName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.lastName === "") {
      toast("Please enter Last Name");
      let titleField = document.getElementsByName("lastName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      return;
    } else if (state.companyName === "") {
      let titleField = document.getElementsByName("companyName")[0];
      titleField.scrollIntoView({ behavior: "smooth", block: "center" });
      titleField.parentElement.parentElement.style.border = "1px solid red";
      setTimeout(() => {
        titleField.parentElement.parentElement.style.border = ""; // Revert to the original style
      }, 3000);
      toast("Please enter Company Name");
      return;
    } else {
      setRegistering(true);
      const dataToUpload = { ...state };
      dataToUpload.user_type = SPOT_REG_CATEGORIES[selectedTab].label;
      try {
        const { data } = await axios.post(
          API_ENDPOINT + "visitor/spot-register",
          dataToUpload
        );
        toast.success(data.message);
        const user = data.user;
        handlePrint(user);
      } catch (error) {
        // Check if the error has a response object
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response has a data object with a message, display it
          toast.error(error.response.data.message);
        } else {
          // Otherwise, fall back to a generic error message
          toast.error("An error occurred while registering. Please try again.");
        }
      } finally {
        setRegistering(false);
        setState({
          title: "",
          firstName: "",
          lastName: "",
          designation: "",
          phone: "",
          email: "",
          companyName: "",
          address: "",
          selectedCountry: "",
          selectedState: "",
          selectedCity: "",
          pin: "",
          website: "",
          telephone: "",
        });
      }
    }
  };

  const handlePrint = (user) => {
    setCurrentUser(user);
    setTimeout(() => {
      printRow(user);
    }, 0);
  };
  // const printNow = useReactToPrint({
  //   content: () => printRef.current,
  //   onAfterPrint: () => {}, // Reset printing flag after printing
  //   onBeforePrint: () => {},
  // });
  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <PrintableRowComponent row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
      <head>
        <style>
          body { font-family: Arial, sans-serif; }
        </style>
      </head>
      <body>
        ${printableContent}
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Box
          sx={{
            maxWidth: 1280,
            ml: "auto",
            mr: "auto",
            bgcolor: "background.paper",
            flexGrow: 1,
          }}
        >
          <Box mt={2} display={"flex"}>
            {/* <Box alignSelf={"center"}>
              <BackBtn />
            </Box> */}
            {/* <Box flex={1} textAlign={"center"}>
              <img src={imtexLogo} style={{ height: "76px" }} />
            </Box> */}
          </Box>
          <Tabs
            sx={{
              ".MuiTabs-indicator": {
                height: "4px", // Increased height of the tab indicator (adjust as needed)
              },
            }}
            value={selectedTab}
            onChange={handleChange}
            centered
          >
            {SPOT_REG_CATEGORIES.map((item, index) => (
              <Tab label={item.label} key={index} sx={{ fontWeight: "bold" }} />
            ))}
          </Tabs>
          <Box maxWidth={840} ml={"auto"} mr={"auto"}>
            <Typography
              mt={3}
              textAlign={"center"}
              color={"rgb(151, 42, 76)"}
              fontWeight={"bold"}
              fontSize={"24px"}
            >
              {" "}
              {SPOT_REG_CATEGORIES[selectedTab].label} Registration{" "}
            </Typography>
            <Box display={"flex"} gap={2} justifyContent={"end"}>
              <LoadingButton
                onClick={handleDownload}
                variant="contained"
                size="small"
              >
                Download Template
              </LoadingButton>
              <LoadingButton variant="contained" size="small">
                Import Excel
              </LoadingButton>
            </Box>
            <InputPage state={state} handleStateChange={handleStateChange}  spot={SPOT_REG_CATEGORIES[selectedTab].label}/>

            <Box textAlign={"center"} mt={2} mb={2}>
              <LoadingButton
                loading={registering}
                onClick={onClickSubmit}
                variant="contained"
              >
                Save & Print
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      
      </Box>
    </ThemeProvider>
  );
};

export default SpotRegistration;
