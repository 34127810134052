import CopyrightIcon from "@mui/icons-material/Copyright";
import LanguageIcon from "@mui/icons-material/Language";
import { Container } from "@mui/material";

const Footer = () => {
  // CSS Styling
  class footerStyles {
    static get footerContainer() {
      return {
        height: "50px",
        width: "100%",
        color: "white",
        background: "var(--dark-color)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "8px",
        marginTop: "70px",
        paddingLeft: "50px",
        paddingRight: "50px",
      };
    }

    static get footerTitle() {
      return {
        display: "flex",
        alignItems: "center",
        fontFamily: '"DM Sans", sans-serif',
        fontSize: "25px",
        fontWeight: "1000",
      };
    }

    static get copyright() {
      return {
        display: "flex",
        alignItems: "center",
        gap: "3px",
        fontFamily: '"DM Sans", sans-serif',
        fontSize: "13px",
      };
    }
  }

  return (
    <Container maxWidth="xl" style={footerStyles.footerContainer}>
      <h3 style={footerStyles.footerTitle}>
        EXP
        <LanguageIcon fontSize="medium" />
        PLANNER
      </h3>
      <h6 style={footerStyles.copyright}>
        <CopyrightIcon fontSize="extra-small" />
        IMTMA 2024. All rights reserved
      </h6>
    </Container>
  );
};

export default Footer;
