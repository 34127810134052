import React, { useState, useEffect } from "react";
import CommonHdr from "../commonHdr";
import BarChartIndustryWise from "./barChartIndustryWise";
import { Container } from "@mui/material";
import axios from "axios";
import { VISITOR_INDUSTRYWISE_GRAPH_API } from "../../constants/constants";
import exportToExcel from "../ExportExcel/ExportExcel.js";

const IndustryWise = () => {
  const randomColor = () => {
    // Generate a random color
    return `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${
      Math.random() * 255
    }, 0.6)`;
  };

  const [industryWiseMetric, setIndustryWise] = useState([]);
  useEffect(() => {
    const getIndustryWiseData = async () => {
      try {
        const response = await axios.get(`${VISITOR_INDUSTRYWISE_GRAPH_API}`);
        //console.log(response.data)
        if (response.data !== null) {
          setIndustryWise(response.data);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getIndustryWiseData();
  }, []);

  // Static Data For Bar Chart
  const industryWiseData = {
    labels: industryWiseMetric.map((val) => val._id),
    datasets: [
      {
        axis: "y",
        label: "",
        barThickness: 20,
        borderRadius: 10,
        backgroundColor: industryWiseMetric.map((val) => randomColor()),
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: industryWiseMetric.map((val) => val.totalUsers),
        datalabels: {
          align: "end",
          anchor: "end",
        },
      },
    ],
  };

  const handleClick = () => {
    exportToExcel(industryWiseMetric, "industryWise.xlsx");
  };
  // CSS Styling
  class industryWiseStyles {
    static get industryWiseContainer() {
      return {
        background: "#ffffff",
        height: "1200px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }

  return (
    <Container maxWidth="xl" style={industryWiseStyles.industryWiseContainer}>
      <CommonHdr hdr="Industry Wise" onClick={handleClick} />
      <div style={{ width: "auto", height: "auto" }}>
        <BarChartIndustryWise data={industryWiseData} />
      </div>
    </Container>
  );
};

export default IndustryWise;
