
import { Button } from "react-bootstrap";
import IosShareIcon from "@mui/icons-material/IosShare";
import Switch from "@mui/material/Switch";

const CommonHdr = (props) => {



  // CSS Styling
  class commonHdrStyles {
    static get commonHdrContainer() {
      return {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }
    }

    static get commonHdrTitle() {
      return {
        fontFamily: '"DM Sans", sans-serif',
        fontSize: '16px',
        color: 'var(--title-color)',
        paddingLeft: '10px',
        paddingTop: '10px'
      }
    }

    static get exportBtn() {
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        background: 'var(--btn-bg-color)',
        fontFamily: '"DM Sans", sans-serif',
        border: 'none',
        borderRadius: '8px',
        fontSize: '12px',
        color: 'var(--dark-color)',
        marginTop: '10px',
        marginRight: '10px'
      }
    }
    static get btns() {
      return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px'
      }
    }
  }
  

  return (
    <div style={commonHdrStyles.commonHdrContainer}>
        <h5 style={commonHdrStyles.commonHdrTitle}>
          {props.hdr}
        </h5>
        <div style={commonHdrStyles.btns}>
        {props.toggleBtn && (
          <Switch
            checked={props.toggle}
            onChange={() => props.setToggle(!props.toggle)}
            name="toggle"
            color="primary"
          />
        )}
        { props.exportBtn &&
          <Button style={commonHdrStyles.exportBtn} onClick={props.onClick}>
            <IosShareIcon fontSize="extra-small" />
            Export
          </Button>
        }
      </div>
    </div>
  );
};

export default CommonHdr;
