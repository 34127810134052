import DesignationWise from "../components/DesignationWise/designationWise";
import DepartmentWise from "../components/DepartmentWise/departmentWise";
import HourlyFootfall from "../components/HourlyFootfall/hourlyFootfall";
import UserTypeFootfall from "../components/UserTypeFootfall/userTypeFootfall";
import HourlyFootfallLine from "../components/HourlyFootfall/hourlyFootfallLine";
// import HourlyFootfallStacked from "../components/HourlyFootfall/hourlyFootfallStacked";
// import HourlyFootfallStacked from "../components/HourlyFootfall/hourlyFootfallStacked";
import TabularData from "../components/tabelData/tabularData.js";
import { useState } from "react";
import TabularDataHourly from "../components/HourlyFootfall/tabularDataHourly";
// import { FOOTFULL_COUNT_API } from "../constants/constants.js";
// import axios from "axios";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import {
  VisitorMetrics,
  // Stats,
  IndustryWise,
  CountrySession,
} from "../components";
// import { Header, Footer } from "./components";
import { Paper, Grid } from "@mui/material";

const FootfallDataReport = () => {
  // function changeKeyss(array, oldKeys, newKeys) {
  //   return array.map((obj) => {
  //     const newObj = {};
  //     Object.keys(obj).forEach((key) => {
  //       const newKey = oldKeys.includes(key)
  // ? newKeys[oldKeys.indexOf(key)]
  //         : key;
  //       newObj[newKey] = obj[key];
  //     });
  //     return newObj;
  //   });
  // }

  // function changeKeys(array, oldKeys, newKeys) {
  //   let bulkStudentCount = 0;
  //   let studentCount = 0;
  //   let restData = [];

  //   array.forEach((obj) => {
  //     if (obj._id === "BULK_STUDENT") {
  //       bulkStudentCount = obj.totalUsers;
  //     } else if (obj._id === "student") {
  //       studentCount = obj.totalUsers;
  //     } else {
  //       restData.push(obj); // Collect the rest of the data
  //     }
  //   });

  //   const totalCount = bulkStudentCount + studentCount;
  //   const newObj = {
  //     [newKeys[oldKeys.indexOf("_id")]]: "Student",
  //     [newKeys[oldKeys.indexOf("totalUsers")]]: totalCount,
  //   };

  //   return [...restData, newObj]; // Combine the rest of the data with the new object
  // }

  // const [footFullCountData, setFootFullCountData] = useState([]);

  // useEffect(() => {
  //   const getFootFullCountData = async () => {
  //     try {
  //       const response = await axios.get(`${FOOTFULL_COUNT_API}`);
  //       if (response.data !== null) {
  //         const modifiedArray = changeKeys(
  //           response.data,
  //           ["_id", "totalUsers"],
  //           ["title", "stat"]
  //         );
  //         const changekeys = changeKeyss(
  //           modifiedArray,
  //           ["_id", "totalUsers"],
  //           ["title", "stat"]
  //         );
  //         //console.log(changekeys);
  //         setFootFullCountData(changekeys);
  //       }
  //     } catch (error) {
  //       alert("Error" + error);
  //     }
  //   };
  //   getFootFullCountData();
  // }, []);
  /// console.log(footFullCountData);

  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          m: 1,
        }}
      >
        <Button
          variant="contained"
          href="#contained-buttons"
          onClick={() => window.location.reload()}
        >
          Refresh
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs>
          {/* <Stats cardData={footFullCountData} /> */}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper>
            <HourlyFootfall />
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper>
            <UserTypeFootfall />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          {toggle ? (
            <Paper>
              <TabularDataHourly setToggle={setToggle} toggle={toggle} />
            </Paper>
          ) : (
            <Paper>
              <HourlyFootfallLine setToggle={setToggle} toggle={toggle} />
            </Paper>
          )}
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs>
          <Paper>
            <TabularData />
          </Paper>
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <IndustryWise />
          </Paper>
        </Grid>
        <Grid item xs={7}>
                <Paper>
                  <CountrySession />
                </Paper>
                <Paper>
                  <VisitorMetrics />
                </Paper>
              </Grid>
      </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper>
            <CountrySession />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <VisitorMetrics />
          </Paper>
        </Grid>
      </Grid> */}

      {/* <Grid container spacing={2}>
              
            </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs>
          <Paper>
            <DesignationWise />
          </Paper>
        </Grid>
      </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs>
          <Paper>
            <DepartmentWise />
          </Paper>
        </Grid>
      </Grid> */}
    </>
  );
};

export default FootfallDataReport;
