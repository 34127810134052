import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/material";
import CommonHdr from "../commonHdr";
import axios from "axios";
import { HOURLY_WISE_API } from "../../constants/constants.js";
import CircularProgress from "@mui/material/CircularProgress";

// function createData(day, hr1, hr2, hr3, hr4, hr5, hr6, hr7) {
//   return { day, hr1, hr2, hr3, hr4, hr5, hr6, hr7 };
// }

// const rows = [
//   createData("Day 1", 20, 20, 20, 60, 35, 80, 45),
//   createData("Day 2", 30, 30, 30, 90, 35, 50, 70),
//   createData("Day 3", 40, 40, 40, 120, 60, 40, 80),
//   createData("Day 4", 50, 50, 50, 150, 80, 50, 30),
//   createData("Day 5", 60, 60, 60, 180, 50, 30, 80),
// ];

export default function TabularDataHourly(props) {
  const dates = ["2024-05-25", "2024-05-26", "2024-05-27", "2024-05-28"];
  const [hourlyDataByDate, setHourlyDataByDate] = useState({});
  const [loading, setLoading] = useState(false);

  const getHourLabel = (hour) => {
    const labels = {
      7: "7am-8am",
      8: "8am-9am",
      9: "9am-10am",
      10: "10am-11am",
      11: "11am-12pm",
      12: "12pm-1pm",
      13: "1pm-2pm",
      14: "2pm-3pm",
      15: "3pm-4pm",
      16: "4pm-5pm",
      17: "5pm-6pm",
      18: "6pm-7pm",
    };
    return labels[hour];
  };

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const day = String(dateObj.getDate()).padStart(2, "0");
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const processHourlyData = (hourlyData) => {
      const hourlyDat = {};
      //debugger;
      dates.forEach((date, index) => {
        const dateObj = new Date(date);
        const nextDay = new Date(dateObj);
        nextDay.setDate(dateObj.getDate() + 1);
        const timestampKey = `day${index + 1}timestamp`;
        const dayData = hourlyData.filter((data) => {
          if (data.role !== "EXHIBITOR" && data.role !== "SERVICE") {
            const createdDate = new Date(data[timestampKey]);
            return createdDate >= dateObj && createdDate < nextDay;
          }
        });
        //console.log(dayData)
        const hourlyCounts = {
          "7am-8am": 0,
          "8am-9am": 0,
          "9am-10am": 0,
          "10am-11am": 0,
          "11am-12pm": 0,
          "12pm-1pm": 0,
          "1pm-2pm": 0,
          "2pm-3pm": 0,
          "3pm-4pm": 0,
          "4pm-5pm": 0,
          "5pm-6pm": 0,
          "6pm-7pm": 0,
        };
        let totalCount = 0;
        dayData.forEach((dat1) => {
          const hour = new Date(dat1[timestampKey]).getHours();
          //console.log(hour);
          const hourLabel = getHourLabel(hour);
          //console.log(hourLabel);
          if (hourLabel) {
            hourlyCounts[hourLabel]++;
            totalCount++;
          }
        });

        hourlyDat[date] = { hourlyCounts, totalCount };
        //console.log(hourlyDat);
      });

      setHourlyDataByDate(hourlyDat);
    };

    const getHourlyWiseData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(HOURLY_WISE_API);
        if (response.data !== null) {
          processHourlyData(response.data);
        }
      } catch (error) {
        alert("Error: " + error);
      } finally {
        setLoading(false);
      }
    };
    getHourlyWiseData();
    // Cleanup interval on component unmount
  }, []);
  // CSS Styling
  class tabularDataStyles {
    static get tabularDataContainer() {
      return {
        background: "#ffffff",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
        overflowX:"scroll",
      };
    }
    static get DateCell() {
      return {
        padding: "0px !important",
      };
    }
  }

  return (
    <Container maxWidth="xl" style={tabularDataStyles.tabularDataContainer}>
      <CommonHdr
        hdr="Day Wise Hourly Footfall"
        toggleBtn={true}
        exportBtn={false}
        setToggle={props.setToggle}
        toggle={props.toggle}
      />

      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Day</TableCell>
            <TableCell align="center">(7am-8am)</TableCell>
            <TableCell align="center">(8am-9am)</TableCell>
            <TableCell align="center">(9am-10am)</TableCell>
            <TableCell align="center">(10am-11pm)</TableCell>
            <TableCell align="center">(11pm-12pm)</TableCell>
            <TableCell align="center">(12pm-1pm)</TableCell>
            <TableCell align="center">(1pm-2pm)</TableCell>
            <TableCell align="center">(2pm-3pm)</TableCell>
            <TableCell align="center">(3pm-4pm)</TableCell>
            <TableCell align="center">(4pm-5pm)</TableCell>
            <TableCell align="center">(5pm-6pm)</TableCell>
            <TableCell align="center">(6pm-7pm)</TableCell>
            <TableCell align="center">Total Visitor</TableCell>
          </TableRow>
        </TableHead>
        {loading && <CircularProgress color="inherit" />}
        <TableBody>
          {Object.entries(hourlyDataByDate).map(
            ([date, { hourlyCounts, totalCount }], index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{ padding: "0px", width: "100px" }}
                  component="th"
                  scope="row"
                >
                  {formatDate(date)}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {hourlyCounts["7am-8am"]}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {hourlyCounts["8am-9am"]}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {hourlyCounts["9am-10am"]}
                </TableCell>
                <TableCell align="center">
                  {hourlyCounts["10am-11am"]}
                </TableCell>
                <TableCell align="center">
                  {hourlyCounts["11am-12pm"]}
                </TableCell>
                <TableCell align="center">{hourlyCounts["12pm-1pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["1pm-2pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["2pm-3pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["3pm-4pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["4pm-5pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["5pm-6pm"]}</TableCell>
                <TableCell align="center">{hourlyCounts["6pm-7pm"]}</TableCell>
                <TableCell align="center">{totalCount}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Container>
  );
}
