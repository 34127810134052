
import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChartIndustryWise = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (
      props.data &&
      props.data.labels.length > 0 &&
      props.data.datasets.length > 0
    ) {
      const ctx = chartRef.current.getContext("2d");
      const chart = new Chart(ctx, {
        type: "bar",
        data: props.data,
        plugins: [ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              color: 'black',
              font: {
                size: 12
              },
              formatter: function(value) {
                return value;
              }    
            },
            legend: {
                display: false,
             } },
          indexAxis: "y",
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: false,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [props.data]);

  return (
      <canvas
        ref={chartRef}
        width="100%"
        height="1150px"
        style={{paddingLeft: "15px", paddingRight: "10px", paddingBottom: "10px"}}
      />
  );
};

export default BarChartIndustryWise;
