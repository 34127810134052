import DesignationWise from "../components/DesignationWise/designationWise";
import DepartmentWise from "../components/DepartmentWise/departmentWise";
import React, { useEffect, useState } from "react";
import {
  VisitorMetrics,
  Stats,
  IndustryWise,
  // CountryWise,
  CountrySession,
  // AboutShow,
} from "../components";
import { Paper, Grid } from "@mui/material";
import axios from "axios";
import { FOOTFULL_COUNT_API } from "../constants/constants";

const RegistrationDeskReport = () => {
  // const initialCardDataStats = {
  //   registrationCount: 0,
  //   exhibitorsCount: 0,
  //   studentCount: 0,
  // };
  // const [cardDataStats, setCardDataStats] = useState(initialCardDataStats);

  // useEffect(() => {
  // const getVisitorMetricsData = async () => {
  //   try {
  //     const response = await axios.get(`${VISITOR_COUNT_API}`);

  //     if (response.data !== null) {
  //       //console.log(response.data)
  //       setCardDataStats({
  //         registrationCount: response.data.visitorCount,
  //         exhibitorsCount: response.data.exhibitorCount,
  //         studentCount: response.data.studentCount,
  //       });
  //       //  console.log(cardData)
  //     }
  //   } catch (error) {
  //     alert("Error" + error);
  //   }
  // };
  // getVisitorMetricsData();
  function changeKeyss(array, oldKeys, newKeys) {
    return array.map((obj) => {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        const newKey = oldKeys.includes(key)
          ? newKeys[oldKeys.indexOf(key)]
          : key;
        newObj[newKey] = obj[key];
      });
      return newObj;
    });
  }

  function changeKeys(array, oldKeys, newKeys) {
    let bulkStudentCount = 0;
    let studentCount = 0;
    let studentOnlineCount = 0;
    let visitorCount = 0;
    let sportVisitorCount = 0;
    let delegationCount = 0;
    let vipCount = 0;
    let ecCount = 0;
    let exCount = 0;
    let exNCount = 0;
    let servCount = 0;

    let restData = [];

    array.forEach((obj) => {
      if (obj._id === "BULK_STUDENT") {
        bulkStudentCount = obj.totalUsers;
      } else if (obj._id === "student") {
        studentCount = obj.totalUsers;
      } else if (obj._id === "STUDENT") {
        studentOnlineCount = obj.totalUsers;
      } else if (obj._id === "visitor") {
        visitorCount = obj.totalUsers;
      } else if (obj._id === "VISITOR") {
        sportVisitorCount = obj.totalUsers;
      } else if (obj._id === "DELEGATION") {
        delegationCount = obj.totalUsers;
      } else if (obj._id === "VIP") {
        vipCount = obj.totalUsers;
      } else if (obj._id === "EC") {
        ecCount = obj.totalUsers;
      } else if (obj._id === "EXHIBITOR") {
        exCount = obj.totalUsers;
      } else if (obj._id === "exhibitor") {
        exNCount = obj.totalUsers;
      } else if (obj._id === "SERVICE") {
        servCount = obj.totalUsers;
      } else {
        restData.push(obj); // Collect the rest of the data
      }
    });

    const totalCount = bulkStudentCount + studentCount + studentOnlineCount;
    const totalVisitorCount = visitorCount + sportVisitorCount;
    const totalVipCount = delegationCount + vipCount + ecCount;

    const newObj = {
      [newKeys[oldKeys.indexOf("_id")]]: "Student",
      [newKeys[oldKeys.indexOf("totalUsers")]]: totalCount,
    };
    const newVisObj = {
      [newKeys[oldKeys.indexOf("_id")]]: "Visitor",
      [newKeys[oldKeys.indexOf("totalUsers")]]: totalVisitorCount,
    };

    const newThreeObj = {
      [newKeys[oldKeys.indexOf("_id")]]: "Vip",
      [newKeys[oldKeys.indexOf("totalUsers")]]: totalVipCount,
    };

    return [...restData, newObj, newVisObj, newThreeObj]; // Combine the rest of the data with the new object
  }

  const [footFullCountData, setFootFullCountData] = useState([]);

  useEffect(() => {
    const getFootFullCountData = async () => {
      try {
        const response = await axios.get(`${FOOTFULL_COUNT_API}`);
        if (response.data !== null) {
          console.log(response.data);
          const modifiedArray = changeKeys(
            response.data,
            ["_id", "totalUsers"],
            ["title", "stat"]
          );
          const changekeys = changeKeyss(
            modifiedArray,
            ["_id", "totalUsers"],
            ["title", "stat"]
          );
          console.log(changekeys);
          setFootFullCountData(changekeys);
        }
      } catch (error) {
        alert("Error" + error);
      }
    };
    getFootFullCountData();
  }, []);

  // Static Data For Stat Cards

  return (
    <>
      <Stats cardData={footFullCountData} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper>
            <VisitorMetrics />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <IndustryWise />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <CountrySession />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <DesignationWise />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <DepartmentWise />
          </Paper>
        </Grid>
      </Grid>

      {/* <Stats cardData={cardData} />
            <div className="row">
              <div className="col-lg">
                <VisitorMetrics />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <IndustryWise />
              </div>
              <div className="col-lg-6">
                <CountrySession />
              </div>
            </div> */}
      {/* <div className="row">
              <div className="col-lg-6">
                <CountryWise />
              </div>
              <div className="col-lg-6">
                <AboutShow />
              </div>
            </div> */}
      {/* <div className="row">
              <div className="col-lg-5">
                <DesignationWise />
              </div>
              <div className="col-lg-7">
                <DepartmentWise />
              </div>
            </div> */}
    </>
  );
};

export default RegistrationDeskReport;
