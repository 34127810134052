import React, { useEffect, useState } from "react";
import BarChartHourlyFootfall from "./barChartHourlyFootfall";
import CommonHdr from "../commonHdr";
import { Container } from "@mui/material";
import axios from "axios";
import { DAY_WISE_API } from "../../constants/constants.js";
import CircularProgress from "@mui/material/CircularProgress";

const HourlyFootfall = (props) => {
  const [visitorData, setVisitorData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getDayWiseData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${DAY_WISE_API}`);

        if (response.data !== null) {
          // Initialize variables
          function formatDate(date) {
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
          }

          let day1 = 0;
          let day2 = 0;
          let day3 = 0;
          let day4 = 0;
          let day1timestamp, day2timestamp, day3timestamp, day4timestamp;

          // Loop through response data to calculate day1 and day2 totals and extract timestamps
          //debugger;
          response.data.forEach((item) => {
            if (item.role !== "EXHIBITOR" && item.role !== "SERVICE") {
              //debugger;
              if (item.day1 !== undefined) {
                day1 += item.day1;
              }
              if (item.day2 !== undefined) {
                day2 += item.day2;
              }
              if (item.day3 !== undefined) {
                day3 += item.day3;
              }
              if (item.day4 !== undefined) {
                day4 += item.day4;
              }
              if (item.day1timestamp !== undefined && !day1timestamp) {
                day1timestamp = item.day1timestamp;
              }
              if (item.day2timestamp !== undefined && !day2timestamp) {
                day2timestamp = item.day2timestamp;
              }
              if (item.day3timestamp !== undefined && !day3timestamp) {
                day3timestamp = item.day3timestamp;
              }
              if (item.day4timestamp !== undefined && !day4timestamp) {
                day4timestamp = item.day4timestamp;
              }
            }
          });
          //console.log(result);
          // Convert timestamps to date strings
          const date1 = day1timestamp
            ? formatDate(new Date(day1timestamp))
            : "";
          const date2 = day2timestamp
            ? formatDate(new Date(day2timestamp))
            : "";
          const date3 = day3timestamp
            ? formatDate(new Date(day3timestamp))
            : "";
          const date4 = day4timestamp
            ? formatDate(new Date(day4timestamp))
            : "";

          // Create array of objects for day data
          const convertedArray = [
            { count: day1, date: date1 },
            { count: day2, date: date2 },
            { count: day3, date: date3 },
            { count: day4, date: date4 },
          ];
          console.log(convertedArray);
          setVisitorData(convertedArray);
        }
      } catch (error) {
        alert("Error" + error);
      } finally {
        setLoading(false);
      }
    };
    getDayWiseData();
    //console.log(visitorData);
    // const interval = setInterval(getDayWiseData, 10000); // Fetch every 5 seconds
    // return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  //console.log(day1);
  // Static Data For Bar Chart
  const hourlyFootfallData = {
    labels: visitorData.map((date) => (date.date ? date.date : "")),
    datasets: [
      {
        label: "Daywise Footfall",
        barThickness: 45,
        backgroundColor: "#D7ECFB",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        data: visitorData.map((count) => (count.count ? count.count : "")),
        datalabels: {
          align: "start",
          anchor: "end",
        },
      },
    ],
  };

  // CSS Styling
  class hourlyFootfallStyles {
    static get hourlyFootfallContainer() {
      return {
        background: "#ffffff",
        height: "350px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "8px",
      };
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={hourlyFootfallStyles.hourlyFootfallContainer}
    >
      <CommonHdr hdr="Day Wise Footfall" />
      {loading && <CircularProgress color="inherit" />}
      <div style={{ width: "auto", height: "auto" }}>
        <BarChartHourlyFootfall data={hourlyFootfallData} />
      </div>
    </Container>
  );
};

export default HourlyFootfall;
