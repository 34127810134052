import {
    Box,
    Button,
    FormControl,
    IconButton,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";
  import React, { useRef, useState } from "react";

import useSpotVisitors from "./hooks/useSpotVisitors";
  import BackBtn from "../../components/widgets/BackBtn";

  import CustomCircularP from "../widgets/CustomCircularP";
//   import { BadgeToPrint } from "../dashboard/visitor/BadgeToPrint";

  import { useReactToPrint } from "react-to-print";
  import Keyboard from "react-simple-keyboard";
  import "react-simple-keyboard/build/css/index.css";
  import QRCode from "react-qr-code";
  import ReactDOMServer from "react-dom/server";
  import { useParams } from "react-router-dom";

  const SPOT_REG_CATEGORIES = [
    { label: "VISITOR", content: "Visitor content goes here." },
    { label: "EXHIBITOR", content: "Exhibitor content goes here." },
    { label: "DELEGATION", content: "Delegation content goes here." },
    { label: "VIP", content: "VIP content goes here." },
    { label: "EC", content: "EC content goes here." },
    { label: "SERVICE", content: "Service content goes here." },
    { label: "MEDIA", content: "Media content goes here." },
    { label: "STUDENT", content: "Student content goes here." },
    { label: "UDAAN", content: "UDAAN content goes here." },
    { label: "SELF_REG_STUDENT", content: "" },
    { label: "MOLDEXFASTNEX", content: "" },
  ];
  class PrintableRowComponent extends React.Component {
    render() {
      const { row } = this.props;
      const vCard = [
        "BEGIN:VCARD",
        "VERSION:3.0",
        `NOTE: Barcode ID: ${row._id}`,
        `N:${row.lastName};${row.firstName}${row.name} ;;;`,
        `FN:${row.title} ${row.firstName} ${row.lastName}${row.name}`,
        `ORG:${row.companyName}${row.instituteName}`,
        `TITLE:${row.designation}${row.education}`,
        `EMAIL;TYPE=PREF,INTERNET:${row.email}`,
        "END:VCARD",
      ].join("\n");
      return (
        <div
          style={{
            textAlign: "center",
            width: "9.8cm",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div
              style={{ marginTop: "5.6cm", fontWeight: "bold", fontSize: "21px" }}
            >
              {row.firstName?.toUpperCase()} {row.lastName?.toUpperCase()}
              {row.name?.toUpperCase()}
            </div>
            <div style={{ fontSize: "19px", marginTop: "12px" }}>
              {row.companyName?.toUpperCase()}
              {row.instituteName?.toUpperCase()}
            </div>
          </div>
          <div style={{ marginTop: "16px" }}>
            <QRCode value={vCard} size={130} />
          </div>
        </div>
      );
    }
  }
  const SpotUsers = () => {
    // const { id } = useParams();
    const [selectedFilter, setSelectedFilter] = useState("");
    const [searchQ, setSearchQ] = useState("");
    const [currentUser, setCurrentUser] = useState("");
    const [page, setPage] = useState(1);
    const printRef = useRef();
    const { data, isLoading, isError, error, refetchVisitors } = useSpotVisitors(
      page,
      searchQ,
      selectedFilter
    );
    const handleSearch = (e) => {
      const vl = e.target.value;
      setSearchQ(vl);
    };
    const onKeyPress = (button) => {
      console.log("Button pressed", button);
      // Handle specific button presses if needed
    };
    const handlePrint = (user) => {
      setTimeout(() => {
        printRow(user);
      }, 0);
    };
    const printRow = (row) => {
      const printableContent = ReactDOMServer.renderToString(
        <PrintableRowComponent row={row} />
      );
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus(); // For some browsers to trigger the print dialog
      printWindow.print();
      printWindow.close();
    };
    // if (
    //   id !==
    //   "7899xxxxfghllksjhcbshdskhdsdjsxxxsdsdsdsdsdnnmsdsmlskdkdlskdssdhsjdhsjdskdsdsbndbsmmjshdjshdjsdsjhdsjdhsjdhsjmsdksjdksjdksjdskjdskklskdlsdksjshdjshfjshdskjdskdjskdjdjksdlskdlskdlsdfjdjlskdlsldkjshdsjdhskdjskdjskd"
    // ) {
    //   return <div>ok i m here</div>;
    // }
  
    // const printRow = (row) => {
    //   const printableContent = ReactDOMServer.renderToString(
    //     <PrintableRowComponent row={row} />
    //   );
  
    //   // Create a temporary element for printing
    //   const printElement = document.createElement("div");
    //   printElement.innerHTML = `
    //     <html>
    //       <head>
    //         <style>
    //           body { font-family: Arial, sans-serif; }
    //         </style>
    //       </head>
    //       <body>
    //         ${printableContent}
    //       </body>
    //     </html>
    //   `;
  
    //   // Print the content of the temporary element
    //   window.print();
  
    //   // Remove the temporary element
    //   printElement.remove();
    // };
    return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
        <Box flexGrow={1} maxWidth={1280} width={"100%"} ml={"auto"} mr={"auto"}>
          <Box mt={2} display={"flex"}>
            <Box alignSelf={"center"}>
              <BackBtn />
            </Box>
            <Typography
              variant="h5"
              ml={2}
              sx={{ fontWeight: "bold", alignSelf: "center" }}
            >
              All Spot Users
            </Typography>
            <Box flex={1} ml={2} mr={2}>
              {/* <SearchBar
                label="Search Users by Name/Company Name/Email"
                onSearch={handleSearch}
                cstyle={{ margin: "0 auto" }}
                mystyle={{ borderRadius: 0 }}
              /> */}
  
              <Paper
                sx={{
                  padding: "2px 4px 2px 10px",
                  backgroundColor: "#f4f4f4",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder={"Search Users by Name/Company Name/Email/mobile"}
                  value={searchQ}
                  onChange={handleSearch}
                  inputProps={{
                    "aria-label":
                      "Search Users by Name/Company Name/Email/mobile",
                  }}
                />
                <IconButton type="submit" sx={{ p: "8px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
              </Paper>
              <Keyboard
                onChange={(input) => {
                  setSearchQ(input);
                }}
                onKeyPress={onKeyPress}
              />
            </Box>
            <FormControl
              sx={{ maxWidth: "200px", alignSelf: "center" }}
              fullWidth
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Filter by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="selectedCountry"
                value={selectedFilter}
                label="Filter by"
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <MenuItem value={""}>ALL</MenuItem>
                
                {SPOT_REG_CATEGORIES.map((target, i) => (
                <MenuItem key={i} value={target.label}>
                  {target.label}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <CustomCircularP show={isLoading} />
            <TableContainer sx={{ mt: 2 }} component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Designation</TableCell>
                    <TableCell align="right">Company</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Phone</TableCell>
                    <TableCell align="right">Print</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {data?.data?.map((row) => (
                  <TableRow
                    key={row.user_id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.title} {row.firstName} {row.lastName}
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.designation}
                      {row.education}
                    </TableCell>
                    <TableCell align="right">
                      {row.companyName}
                      {row.instituteName}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">
                      {row.phone}
                      {row.mobile}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => handlePrint(row)}
                      >
                        Print
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              </Table>
            </TableContainer>
            {/* <Box display={"flex"} mt={4} justifyContent={"center"}>
              {currentUser && <BadgeToPrint ref={printRef} user={currentUser} />}
            </Box> */}
          </Box>
        </Box>
        
      </Box>
    );
  };
  
  export default SpotUsers;
  